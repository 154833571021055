@font-face {
  font-family: 'Noto Serif Sinhala';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 300;
  src: local('Noto Serif Sinhala'), local('NotoSerifSinhala-Light'),
    url(./noto-serif/noto-serif-sinhala-light.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Serif Sinhala';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 400;
  src: local('Noto Serif Sinhala'), local('NotoSerifSinhala-Regular'),
    url(./noto-serif/noto-serif-sinhala-regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Serif Sinhala';
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 500;
  src: local('Noto Serif Sinhala'), local('NotoSerifSinhala-SemiBold'),
    url(./noto-serif/noto-serif-sinhala-semi-bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Serif Sinhala';
  font-style: 'bold';
  font-display: 'swap';
  font-weight: 700;
  src: local('Noto Serif Sinhala'), local('NotoSerifSinhala-Bold'),
    url(./noto-serif/noto-serif-sinhala-bold.woff2) format('woff2');
}
